<template>
  <base-page-layout title="Great-Expectations Reports">
    <v-card class="mt-3">
      <v-card-title>
        <v-text-field v-model="search" class="pt-0 mt-0" width="200" hide-details single-line="" placeholder="Busca" append-icon="search" />
        <v-spacer />
      </v-card-title>
      <v-card-text class="pa-0">
        <v-data-table :items="geReports" :headers="headers" :search="search">
          <template v-slot:item="{ item }">
            <tr style="cursor: pointer;">
              <td>{{ formatUrl(item.doc_path) }}</td>
              <td>{{ formatQuery(item.doc_path) }}</td>
              <td>{{ formatDate(item.created_at) }}</td>
              <td class="text-xs-center">
                <v-btn small color="primary" @click.stop="openLog(formatGEPath(item.doc_path))">Abrir link</v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </base-page-layout>
</template>

<script>
import { QUERY_GET_REPORTS } from '@/modules/accounts/graphql'

export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue')
  },
  data: () => ({
    accountId: '',
    search: ''
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Task',
          value: 'task',
          width: '15%'
        },
        {
          text: 'Query',
          value: 'query',
          width: '15%'
        },
        {
          text: 'Date',
          value: 'created_at',
          width: '10%'
        },
        {
          text: 'Reports',
          value: 'go_to_path',
          width: '5%',
          sortable: false
        }
      ]
    }
  },
  apollo: {
    geReports: {
      query: QUERY_GET_REPORTS,
      fetchPolicy: 'network-only',
      variables() {
        return { id: this.accountId }
      }
    }
  },
  mounted() {
    this.accountId = this.$route.params.id
  },
  methods: {
    formatDate(date) {
      return this.$moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY, HH:mm:ss')
    },
    formatGEPath(path) {
      return `https://files-server.mercafacil.com/ge/${path}`
    },
    formatUrl(url) {
      return url.split('/')[1]
    },
    formatQuery(url) {
      return url.split('/')[2]
    },
    openLog(url) {
      window.open(url, '_blank')
    }
  }
}
</script>
